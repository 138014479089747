<template>
  <ul class="sortable-list" v-class-mod:sortable-list="[`size-${size}`, { isDisabled, onlyOnHover }]">
    <li
      v-for="item in listItems"
      :draggable="!isDisabled && !lockedItems.includes(item?.item?.id) && (!onlyOnIcon || iconIsHovering)"
      :style="`order: ${item.dragOrder}`"
      class="sortable-list__item"
      @dragstart="
        (e: DragEvent) => {
          if (validateDragStart(e)) {
            selectedItem = item.dragId;
          }
        }
      "
      @dragend="dragEnd"
      @dragleave.prevent
      @dragover.prevent="() => (item.dragId === selectedItem ? true : false)"
      :key="item.dragId"
      ref="dragElement"
      v-class-mod:sortable-list__item="{
        isDragging: selectedItem === item.dragId,
        draggingIsActive: selectedItem !== -1,
      }"
    >
      <span
        v-if="!isDisabled && !lockedItems.includes(item?.item?.id)"
        class="sortable-list__drag-icon"
        @mouseenter.stop="iconIsHovering = true"
        @mouseleave.stop="iconIsHovering = false"
      />
      <template v-if="selectedItem !== -1 && item.dragId !== selectedItem && !lockedItems.includes(item?.item?.id)">
        <span class="sortable-list__insert sortable-list__insert--above" @dragenter="insert(item.dragId, true)" />
        <span class="sortable-list__insert sortable-list__insert--below" @dragenter="insert(item.dragId, false)" />
      </template>
      <div class="sortable-list__content">
        <slot name="item" v-bind="item.item" />
      </div>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

export interface ChangedObject {
  moving: any;
  next?: any;
  previous?: any;
}

const props = withDefaults(
  defineProps<{
    items: any[];
    isDisabled?: boolean;
    lockedItems?: string[];
    size?: "small" | "medium";
    onlyOnHover?: true;
    onlyOnIcon?: true;
  }>(),
  {
    lockedItems: () => [],
    size: "medium",
  },
);
const emit = defineEmits<{ (e: "changed:list", d: any[]): void; (e: "changed:object", d: ChangedObject): void }>();

const selectedItem = ref<number>(-1);
const dragElement = ref<HTMLElement[]>([]);
const sortedList = ref<number[]>([]);
const iconIsHovering = ref(false);
const listItems = computed(() =>
  props.items.map((item, dragId) => ({ item, dragId, dragOrder: sortedList.value.indexOf(dragId) + 1 })),
);

const insert = (dragId: number, isAbove: boolean) => {
  if (selectedItem.value !== -1) {
    const list = listItems.value.map(({ dragId }) => dragId).filter((id) => id !== selectedItem.value);
    const insertIndex = list.indexOf(dragId) + (isAbove ? 0 : 1);
    list.splice(insertIndex, 0, selectedItem.value);
    sortedList.value = list;
  }
};

const validateDragStart = (e: DragEvent) => {
  for (const t of dragElement.value) {
    if (t === e.target) {
      return true;
    }
  }
  return false;
};

const dragEnd = () => {
  if (selectedItem.value >= 0 && sortedList.value.length) {
    const moving = listItems.value[selectedItem.value].item;

    let list = [...listItems.value];
    list.sort((a, b) => a.dragOrder - b.dragOrder);

    const index = list.findIndex((l) => l.dragId === selectedItem.value);
    list = list.map(({ item }) => item);
    const next = list[index + 1];
    const previous = list[index - 1];

    emit("changed:list", list);
    emit("changed:object", { moving, next, previous });
  }
  selectedItem.value = -1;
  sortedList.value = [];
};
</script>

<style lang="scss" scoped>
.sortable-list {
  $block: &;
  display: flex;
  flex-flow: column;
  --drag-icon-margin-left: 10px;
  --drag-icon-margin-right: 10px;
  &__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: relative;
    transition: 0s;
    &--isDragging {
      > * {
        transform: translateX(-9999px);
        transition: transform 0.1s;
      }
      #{$block}__drag-icon {
        cursor: grabbing;
        color: var(--black-90);
      }
    }
    &--draggingIsActive:not(#{&}--isDragging) #{$block}__drag-icon {
      pointer-events: none;
    }
  }
  &__drag-icon {
    align-self: flex-start;
    margin: 10px var(--drag-icon-margin-left) 10px var(--drag-icon-margin-right);
    width: var(--app-icon-size-medium);
    font-size: var(--app-icon-size-medium);
    @include icon-button(-6px);
    @include icon-after($icon-drag_indicator);
    color: var(--black-50);
    cursor: grab;
    & + #{$block}__content {
      width: calc(100% - var(--app-icon-size-medium) - var(--drag-icon-margin-left) - var(--drag-icon-margin-right));
    }
    &:hover {
      color: var(--black-90);
    }
  }
  &__content {
    width: 100%;
    z-index: 1;
  }
  &__insert {
    position: absolute;
    height: 50%;
    left: 0;
    right: 0;
    z-index: 2;
    &--above {
      top: 0;
    }
    &--below {
      bottom: 0;
    }
  }

  &--size-small {
    --drag-icon-margin-left: 4px;
    --drag-icon-margin-right: 4px;
  }

  &--onlyOnHover #{&}__item:not(:hover) #{&}__drag-icon {
    opacity: 0;
  }
}
</style>
